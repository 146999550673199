import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';

import { withTheme } from '../../theme';

export const BaseError = styled.div`
  color: ${withTheme(theme => theme.warningColor.darken(0.5).string())};
  background-color: ${withTheme(theme =>
    theme.warningColor.alpha(0.25).string()
  )};
  font-size: ${withTheme(theme => theme.textSize.s)};
  line-height: ${withTheme(theme => theme.lineHeight.s)};
  padding: 1.25em;
  border-radius: ${withTheme(theme => theme.border.radius.default)};
`;

export const basePoseConfig = {
  enter: {
    opacity: 1,
    transition: {
      default: { duration: 150 },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 75 },
  },
};

export default (Error = BaseError, poseConfig = basePoseConfig) => {
  const PosedError = posed(Error)(poseConfig);

  return ({ id, isVisible, children }) => (
    <PoseGroup>
      {isVisible ? <PosedError key={id}>{children}</PosedError> : null}
    </PoseGroup>
  );
};
