import { compose, withProps } from 'recompose';

import { withLabel } from './lib/components/Label';
import SelectControl from './lib/components/controls/SelectControl';

import themes from './themes';

export default compose(
  withProps({
    options: Object.entries(themes).reduce(
      (acc, [key, { label }]) => ({
        ...acc,
        [key]: label,
      }),
      {}
    ),
    isClearable: false,
    menuPlacement: 'top',
  }),
  withLabel('Theme')
)(SelectControl);
