import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  ExampleWrapper,
  Section,
  PageTitle,
  SectionTitle,
  Subtitle,
  Pre2,
  Code2,
} from './common';

import Link from '../lib/components/Link';
import ButtonLink from '../lib/components/ButtonLink';
import RouterLink from '../lib/components/RouterLink';
import ButtonRouterLink from '../lib/components/ButtonRouterLink';
import { Paragraph } from '../lib/components/typography';
import InlineCode from '../InlineCode';
import Tag from '../Tag';
import DocumentationTable from '../DocumentationTable';
import SyntaxHighlighter from '../SyntaxHighlighter';

const UL = styled.ul`
  display: flex;
  align-items: center;
`;

const LI = styled.li`
  margin-right: 1em;
`;

export default () => (
  <Fragment>
    <Section>
      <PageTitle>Links</PageTitle>
      <Subtitle>
        Use links to allow user to navigate around the website / app.
      </Subtitle>

      <Section>
        <SectionTitle>Traditional Links</SectionTitle>
        <ExampleWrapper>
          <UL>
            <LI>
              <Link href="#link">Default</Link>
            </LI>
            <LI>
              <Link primary href="#link">
                Primary
              </Link>
            </LI>
            <LI>
              <Link danger href="#link">
                Danger
              </Link>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import Link from 'spicy-everything/lib/components/Link';

<Link href="#link">Default</Link>
<Link primary href="#link">Primary</Link>
<Link danger href="#link">Danger</Link>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <Section>
        <SectionTitle>React Router Links</SectionTitle>
        <ExampleWrapper>
          <UL>
            <LI>
              <RouterLink to="/">Default</RouterLink>
            </LI>
            <LI>
              <RouterLink primary to="/">
                Primary
              </RouterLink>
            </LI>
            <LI>
              <RouterLink danger to="/">
                Danger
              </RouterLink>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import RouterLink from 'spicy-everything/lib/components/RouterLink';

<RouterLink to="/">Default</RouterLink>
<RouterLink primary to="/">Primary</RouterLink>
<RouterLink danger to="/">Danger</RouterLink>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <Section>
        <SectionTitle>Button Links</SectionTitle>
        <Paragraph />
        <ExampleWrapper>
          <UL>
            <LI>
              <ButtonLink to="/">Default</ButtonLink>
            </LI>
            <LI>
              <ButtonLink primary to="/">
                Primary
              </ButtonLink>
            </LI>
            <LI>
              <ButtonLink danger to="/">
                Danger
              </ButtonLink>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import ButtonLink from 'spicy-everything/lib/components/ButtonLink';

<ButtonLink to="/">Default</ButtonLink>
<ButtonLink primary to="/">Primary</ButtonLink>
<ButtonLink danger to="/">Danger</ButtonLink>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <Section>
        <SectionTitle>Button React Router Links</SectionTitle>
        <Paragraph />
        <ExampleWrapper>
          <UL>
            <LI>
              <ButtonRouterLink to="/">Default</ButtonRouterLink>
            </LI>
            <LI>
              <ButtonRouterLink primary to="/">
                Primary
              </ButtonRouterLink>
            </LI>
            <LI>
              <ButtonRouterLink danger to="/">
                Danger
              </ButtonRouterLink>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import ButtonRouterLink from 'spicy-everything/lib/components/ButtonRouterLink';

<ButtonRouterLink to="/">Default</ButtonRouterLink>
<ButtonRouterLink primary to="/">Primary</ButtonRouterLink>
<ButtonRouterLink danger to="/">Danger</ButtonRouterLink>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <Section>
        <SectionTitle>Properties</SectionTitle>
        <DocumentationTable>
          <thead>
            <tr>
              <th>Property</th>
              <th>Description</th>
              <th>Default Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InlineCode>href</InlineCode> or <InlineCode>to</InlineCode>
              </td>
              <td>URL to go to.</td>
              <td>
                <InlineCode>.</InlineCode>
              </td>
            </tr>
            <tr>
              <td>
                <InlineCode>default</InlineCode>,{' '}
                <InlineCode>primary</InlineCode>,{' '}
                <InlineCode>danger</InlineCode> <Tag>mutualy exclusive</Tag>
              </td>
              <td>Link look & feel modifiers.</td>
              <td>
                <InlineCode>false</InlineCode> by default, except{' '}
                <InlineCode>default</InlineCode>
              </td>
            </tr>
          </tbody>
        </DocumentationTable>
      </Section>
    </Section>
  </Fragment>
);
