export const is = exactly => value => value === exactly;
export const isDefined = value => value !== undefined;
export const isUndefined = value => value === undefined;
export const isNull = value => value === null;
export const isDefinedAndNotNull = value =>
  value !== undefined && value !== null;
export const isDefinedNotNullAndFinite = value =>
  isDefinedAndNotNull(value) && isFinite(value);
export const areDefinedNotNullAndFinite = (...values) =>
  values.every(isDefinedNotNullAndFinite);

export const isString = value =>
  typeof value === 'string' || value instanceof String;
export const isNumber = value => typeof value === 'number';
export const isObject = value =>
  isDefinedAndNotNull(value) && typeof value === typeof {};
export const isBoolean = value => value === true || value === false;
export const isFunction = value => typeof value === typeof (() => undefined);

export const isArray = value => Array.isArray(value);
export const isArrayOf = valid => value =>
  Array.isArray(value) && value.every(valid);
export const isShaped = shape => object =>
  isObject(object) &&
  Object.entries(shape).every(([key, fn]) => fn(object[key]));
