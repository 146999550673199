import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Code2,
  ExampleWrapper,
  PageTitle,
  Pre2,
  Section,
  SectionTitle,
  Subtitle,
} from './common';

import Button from '../lib/components/Button';
import LinkButton from '../lib/components/LinkButton';
import { Paragraph } from '../lib/components/typography';
import InlineCode from '../InlineCode';
import Tag from '../Tag';
import DocumentationTable from '../DocumentationTable';
import SyntaxHighlighter from '../SyntaxHighlighter';

const UL = styled.ul`
  display: flex;
  align-items: center;
`;

const LI = styled.li`
  margin-right: 1em;
`;

export default () => (
  <Fragment>
    <Section>
      <PageTitle>Buttons</PageTitle>
      <Subtitle>Use buttons to allow user to trigger actions.</Subtitle>

      <Section>
        <SectionTitle>Regular Buttons</SectionTitle>
        <Paragraph>
          Regular buttons are clickable elements visually recognizable by its
          rectangular container.
        </Paragraph>
        <ExampleWrapper>
          <UL>
            <LI>
              <Button onClick={() => alert('Got it.')}>Default</Button>
            </LI>
            <LI>
              <Button primary onClick={() => alert('Got it.')}>
                Primary
              </Button>
            </LI>
            <LI>
              <Button danger onClick={() => alert('Got it.')}>
                Danger
              </Button>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import LinkButton from 'spicy-everything/lib/components/LinkButton';

<Button onClick={() => alert('Got it.')}>Default</Button>
<Button primary onClick={() => alert('Got it.')}>Primary</Button>
<Button danger onClick={() => alert('Got it.')}>Danger</Button>
<Button disabled onClick={() => alert('Got it.')}>Disabled</Button>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <Section>
        <SectionTitle>Link Buttons</SectionTitle>
        <Paragraph>
          Link buttons, are buttons just like others, except they look like
          link.
        </Paragraph>
        <ExampleWrapper>
          <UL>
            <LI>
              <LinkButton onClick={() => alert('Got it.')}>Default</LinkButton>
            </LI>
            <LI>
              <LinkButton primary onClick={() => alert('Got it.')}>
                Primary
              </LinkButton>
            </LI>
            <LI>
              <LinkButton danger onClick={() => alert('Got it.')}>
                Danger
              </LinkButton>
            </LI>
          </UL>
        </ExampleWrapper>
        <Pre2>
          <Code2>
            <SyntaxHighlighter>
              {`import LinkButton from 'spicy-everything/lib/components/LinkButton';

<LinkButton onClick={() => alert('Got it.')}>Default</LinkButton>
<LinkButton primary onClick={() => alert('Got it.')}>Primary</LinkButton>
<LinkButton danger onClick={() => alert('Got it.')}>Danger</LinkButton>
<LinkButton disabled onClick={() => alert('Got it.')}>Disabled</LinkButton>`}
            </SyntaxHighlighter>
          </Code2>
        </Pre2>
      </Section>

      <SectionTitle>Button States</SectionTitle>
      <Paragraph>Buttons can be busy or disabled.</Paragraph>
      <ExampleWrapper>
        <UL>
          <LI>
            <Button disabled onClick={() => alert('Got it.')}>
              Disabled Button
            </Button>
          </LI>
          <LI>
            <Button busy onClick={() => alert('Got it.')}>
              Busy Button
            </Button>
          </LI>
          <LI>
            <LinkButton disabled onClick={() => alert('Got it.')}>
              Disabled LinkButton
            </LinkButton>
          </LI>
          <LI>
            <LinkButton busy onClick={() => alert('Got it.')}>
              Busy LinkButton
            </LinkButton>
          </LI>
        </UL>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import Button from 'spicy-everything/lib/components/Button';
import LinkButton from 'spicy-everything/lib/components/LinkButton';

<Button disabled onClick={() => alert('Got it.')}>Disabled Button</Button>
<Button busy onClick={() => alert('Got it.')}>Busy Button</Button>
<LinkButton disabled onClick={() => alert('Got it.')}>Disabled LinkButton</LinkButton>
<LinkButton busy onClick={() => alert('Got it.')}>Busy LinkButton</LinkButton>`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>

    <Section>
      <SectionTitle>Button Sizes</SectionTitle>
      <Paragraph>
        Buttons can have different sizes, from extra small to extra large.
      </Paragraph>
      <ExampleWrapper>
        <UL>
          <LI>
            <Button xl onClick={() => alert('Got it.')}>
              XL Button
            </Button>
          </LI>
          <LI>
            <Button l onClick={() => alert('Got it.')}>
              L Button
            </Button>
          </LI>
          <LI>
            <Button m onClick={() => alert('Got it.')}>
              Default
            </Button>
          </LI>
          <LI>
            <Button s onClick={() => alert('Got it.')}>
              S Button
            </Button>
          </LI>
          <LI>
            <Button xs onClick={() => alert('Got it.')}>
              XS Button
            </Button>
          </LI>
        </UL>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import Button from 'spicy-everything/lib/components/Button';

<Button xl onClick={() => alert('Got it.')}>XL Button</Button>
<Button l onClick={() => alert('Got it.')}>L Button</Button>
<Button m onClick={() => alert('Got it.')}>Default</Button>
<Button s onClick={() => alert('Got it.')}>S Button</Button>
<Button xs onClick={() => alert('Got it.')}>XS Button</Button>`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>

    <Section>
      <SectionTitle>Properties</SectionTitle>
      <DocumentationTable>
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Default Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InlineCode>onClick</InlineCode>
            </td>
            <td>
              Parameterless function which will be called when the button gets
              clicked.
            </td>
            <td>
              <InlineCode>() => {`{}`}</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>default</InlineCode>, <InlineCode>primary</InlineCode>
              , <InlineCode>danger</InlineCode> <Tag>mutualy exclusive</Tag>
            </td>
            <td>Button look & feel modifiers.</td>
            <td>
              <InlineCode>false</InlineCode> by default, except{' '}
              <InlineCode>default</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>xl</InlineCode>, <InlineCode>l</InlineCode>,{' '}
              <InlineCode>m</InlineCode>, <InlineCode>s</InlineCode>,{' '}
              <InlineCode>xs</InlineCode> <Tag>mutualy exclusive</Tag>
            </td>
            <td>Button size modifiers.</td>
            <td>
              <InlineCode>false</InlineCode> by default, except{' '}
              <InlineCode>m</InlineCode>
            </td>
          </tr>
        </tbody>
      </DocumentationTable>
    </Section>

    <Section>
      <SectionTitle>Look & Feel Modifier Properties</SectionTitle>
      <DocumentationTable>
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Default Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InlineCode>default</InlineCode>
            </td>
            <td>Button look & feel modifiers.</td>
            <td>
              <InlineCode>true</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>primary</InlineCode>
            </td>
            <td>Button look & feel modifiers.</td>
            <td>
              <InlineCode>false</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>danger</InlineCode>
            </td>
            <td>Button look & feel modifiers.</td>
            <td>
              <InlineCode>false</InlineCode>
            </td>
          </tr>
        </tbody>
      </DocumentationTable>
    </Section>

    <Section>
      <SectionTitle>Properties</SectionTitle>
      <DocumentationTable>
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Default Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InlineCode>onClick</InlineCode>
            </td>
            <td>
              Parameterless function which will be called when the button gets
              clicked.
            </td>
            <td>
              <InlineCode>() => {`{}`}</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>default</InlineCode>, <InlineCode>primary</InlineCode>
              , <InlineCode>danger</InlineCode> <Tag>mutualy exclusive</Tag>
            </td>
            <td>Button look & feel modifiers.</td>
            <td>
              <InlineCode>false</InlineCode> by default, except{' '}
              <InlineCode>default</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>xl</InlineCode>, <InlineCode>l</InlineCode>,{' '}
              <InlineCode>m</InlineCode>, <InlineCode>s</InlineCode>,{' '}
              <InlineCode>xs</InlineCode> <Tag>mutualy exclusive</Tag>
            </td>
            <td>Button size modifiers.</td>
            <td>
              <InlineCode>false</InlineCode> by default, except{' '}
              <InlineCode>m</InlineCode>
            </td>
          </tr>
        </tbody>
      </DocumentationTable>
    </Section>
  </Fragment>
);
