import React from 'react';
import styled from 'styled-components';
import { withTheme } from './lib/theme';

const Path = styled.path`
  fill: ${withTheme(theme => theme.sidebar.textColor.string())};
`;

const Logo = () => {
  return (
    <svg
      width="136"
      height="45"
      viewBox="0 0 136 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M28.0056 11.4002C27.0011 10.314 25.4426 8.83451 24.7854 12.1586C24.5601 13.2916 24.0343 14.1625 23.5837 14.8647C23.0955 12.4114 21.6309 9.87388 20.2602 8.16033C19.7438 7.52359 17.9131 4.9673 17.5657 0.70682C17.5094 0.0700881 16.7583 -0.22955 16.2795 0.20118C11.7543 4.30248 9.26637 9.93943 9.17249 16.288C9.17249 16.288 7.28542 14.7056 6.26208 11.756C5.98981 10.9601 4.91954 10.8103 4.4595 11.5125C4.37501 11.6436 4.29051 11.7841 4.21541 11.9152C0.704139 18.0671 -0.976386 25.5487 0.58209 32.5059C3.18268 44.1638 20.3635 47.4223 29.4608 40.0999C38.3611 32.9273 35.5351 19.5934 28.0056 11.4002Z"
          fill="#E9B41A"
        />
        <path
          d="M6 26C6 25.4477 6.44772 25 7 25H28C28.5523 25 29 25.4477 29 26V33C29 33.5523 28.5523 34 28 34H7C6.44772 34 6 33.5523 6 33V26Z"
          fill="#E43213"
        />
      </g>
      <Path
        d="M48.4219 29.4531C48.6094 32.4766 51.1289 34.4102 54.8789 34.4102C58.8867 34.4102 61.3945 32.3828 61.3945 29.1484C61.3945 26.6055 59.9648 25.1992 56.4961 24.3906L54.6328 23.9336C52.4297 23.418 51.5391 22.7266 51.5391 21.5195C51.5391 19.9961 52.9219 19 54.9961 19C56.9648 19 58.3242 19.9727 58.5703 21.5312H61.125C60.9727 18.6836 58.4648 16.6797 55.0312 16.6797C51.3398 16.6797 48.8789 18.6836 48.8789 21.6953C48.8789 24.1797 50.2734 25.6562 53.332 26.3711L55.5117 26.8984C57.75 27.4258 58.7344 28.1992 58.7344 29.4883C58.7344 30.9883 57.1875 32.0781 55.0781 32.0781C52.8164 32.0781 51.2461 31.0586 51.0234 29.4531H48.4219Z"
        fill="black"
      />
      <Path
        d="M71.1797 21.0273C69.4453 21.0273 67.9453 21.8945 67.1719 23.3477H66.9844V21.2383H64.5586V38.2422H67.0781V32.0664H67.2773C67.9453 33.4141 69.3867 34.2109 71.2031 34.2109C74.4258 34.2109 76.4766 31.668 76.4766 27.6133C76.4766 23.5586 74.4258 21.0273 71.1797 21.0273ZM70.4648 31.9492C68.3555 31.9492 67.0312 30.2852 67.0312 27.625C67.0312 24.9531 68.3555 23.2891 70.4766 23.2891C72.6094 23.2891 73.8867 24.918 73.8867 27.6133C73.8867 30.3203 72.6094 31.9492 70.4648 31.9492Z"
        fill="black"
      />
      <Path
        d="M79.5586 34H82.0664V21.2383H79.5586V34ZM80.8125 19.0234C81.6914 19.0234 82.4062 18.2852 82.4062 17.3828C82.4062 16.4688 81.6914 15.7305 80.8125 15.7305C79.9336 15.7305 79.2188 16.4688 79.2188 17.3828C79.2188 18.2852 79.9336 19.0234 80.8125 19.0234Z"
        fill="black"
      />
      <Path
        d="M96.5742 25.4102C96.2461 22.8555 94.3125 20.9922 91.1133 20.9922C87.3984 20.9922 85.1367 23.4883 85.1367 27.5781C85.1367 31.7383 87.4102 34.2461 91.125 34.2461C94.2773 34.2461 96.2461 32.4648 96.5742 29.8984H94.125C93.7969 31.2695 92.707 31.9961 91.1133 31.9961C89.0156 31.9961 87.7148 30.3438 87.7148 27.5781C87.7148 24.8594 89.0039 23.2422 91.1133 23.2422C92.8008 23.2422 93.832 24.168 94.125 25.4102H96.5742Z"
        fill="black"
      />
      <Path
        d="M100.723 38.6289C103.324 38.6289 104.531 37.6328 105.621 34.5273L110.379 21.2383H107.707L104.52 31.375H104.32L101.121 21.2383H98.3789L102.996 34.0469L102.809 34.668C102.375 35.957 101.695 36.4492 100.5 36.4492C100.266 36.4492 99.8789 36.4375 99.6797 36.4023V38.5703C99.9141 38.6055 100.512 38.6289 100.723 38.6289Z"
        fill="black"
      />
      <Path
        d="M115.852 17.0898H113.227V28.1758C113.227 31.8438 115.84 34.4102 120.07 34.4102C124.324 34.4102 126.926 31.8438 126.926 28.1758V17.0898H124.301V27.9414C124.301 30.3086 122.766 31.9492 120.07 31.9492C117.387 31.9492 115.852 30.3086 115.852 27.9414V17.0898Z"
        fill="black"
      />
      <Path d="M133.781 34V17.0898H131.156V34H133.781Z" fill="black" />
      <defs>
        <clipPath id="clip0">
          <rect
            width="35"
            height="44"
            fill="white"
            transform="matrix(1 0 0 -1 0 44)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
