import React, { Fragment } from 'react';
import styled from 'styled-components';
import { compose, withState } from 'recompose';

import {
  ExampleWrapper,
  Section,
  PageTitle,
  SectionTitle,
  Pre2,
  Code2,
} from '../common';
import SelectControl from '../../lib/components/controls/SelectControl';
import ListPickerControl from '../../lib/components/controls/ListPickerControl';
import { isObject } from '../../lib/utilities/check';
import SyntaxHighlighter from '../../SyntaxHighlighter';

const ThinWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 20rem;
`;

const options = {
  1: 'Jalapeno',
  2: 'Habanero',
  3: 'Poblano',
};

const SelectExample = compose(withState('value', 'setValue'))(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>Select Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <SelectControl
            value={value}
            onChange={setValue}
            getOptionValue={option =>
              option ? parseInt(option.value, 10) : undefined
            }
            options={options}
          />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import SelectControl from 'spicy-everything/lib/components/controls/SelectControl';

<SelectControl value={…} onChange={…} options={…} />`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

const ListPickerExample = withState('value', 'setValue')(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>List Picker Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <ListPickerControl
            value={value}
            onChange={value => setValue(value)}
            getOptionValue={value =>
              isObject(value)
                ? `${value.type}${value.room ? value.room : ''}`
                : undefined
            }
            options={[
              { value: { type: 'internal' }, label: 'First option' },
              { value: { type: 'waiting', room: 1 }, label: 'Second option' },
              { value: { type: 'waiting', room: 2 }, label: 'Third option' },
            ]}
          />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import ListPickerControl from 'spicy-everything/lib/components/input/ListPickerControl';

<ListPickerControl
  value={value}
  onChange={value => setValue(value)}
  getOptionValue={value =>
    isObject(value)
      ? \`\${value.type}\${value.room ? value.room : ''}\`
      : undefined
  }
  options={[
    { value: { type: 'internal' }, label: 'First option' },
    { value: { type: 'waiting', room: 1 }, label: 'Second option' },
    { value: { type: 'waiting', room: 2 }, label: 'Third option' },
  ]}
/>`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

export default () => (
  <Fragment>
    <PageTitle>Picker Controls</PageTitle>
    <SelectExample />
    <ListPickerExample />
  </Fragment>
);
