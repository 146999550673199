import styled from 'styled-components';
import { withTheme } from './lib/theme';

export default styled.span`
  white-space: nowrap;
  background: ${withTheme(theme =>
    theme.textColor
      .lighten(6.1)
      .saturate(1.7)
      .string()
  )};
  padding: calc(0.25em + 1px) calc(0.5em + 1px);
  border-radius: 0.25em;
  line-height: calc(1.5em + 2px);
  color: white;
  font-size: 0.5em;
  font-weight: bold;
`;
