import React, { Fragment } from 'react';
import { withState } from 'recompose';
import styled from 'styled-components';

import Input from '../html/Input';

import StackView from '../StackView';
import LinkButton from '../LinkButton';
import Icon from '../Icon';
import { withTheme } from '../../theme';
import ControlError from '../errors/ControlError';

const PasswordInput = styled(Input)`
  font-family: ${withTheme((theme, { fixedWidth }) =>
    fixedWidth ? theme.fontFamily.fixedWidth : 'inherit'
  )};
`;

const VisiblityButton = styled(LinkButton)`
  justify-self: end;
  padding: 0 ${withTheme(theme => theme.input.padding)};
  border: none;
`;

const PasswordControl = ({
  Control = Input,
  Error = ControlError,
  name,
  autoFocus,
  value,
  onChange,
  isDisabled,
  showPassword,
  setShowPassword,
  error,
  hasError,
  ...rest
}) => {
  const toggleVisibility = e => {
    e.preventDefault();

    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      <StackView>
        <PasswordInput
          id={name}
          fixedWidth={showPassword}
          type={showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="current-password"
          autoCapitalize="off"
          autoCorrect="off"
          placeholder="e.g., •••••••••"
          autoFocus={autoFocus || false}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          disabled={isDisabled}
          error={error}
          hasError={hasError}
          {...rest}
        />
        <VisiblityButton
          type="button"
          onClick={toggleVisibility}
          disabled={isDisabled}
        >
          <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
        </VisiblityButton>
      </StackView>
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </Fragment>
  );
};

export default withState('showPassword', 'setShowPassword', false)(
  PasswordControl
);
