import { withProps } from 'recompose';

import PrismLight, {
  registerLanguage,
} from 'react-syntax-highlighter/dist/prism-light';
import jsx from 'react-syntax-highlighter/dist/languages/prism/jsx';
import ateliersulphurpool from 'react-syntax-highlighter/dist/styles/prism/base16-ateliersulphurpool.light';

registerLanguage('jsx', jsx);

export default withProps({
  language: 'jsx',
  style: ateliersulphurpool,
})(PrismLight);
