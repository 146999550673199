import format from 'date-fns/format';
import { isString, isNumber } from '../check';

export const isInHoursAndMinutesFormat = value => {
  if (!isString(value)) {
    return false;
  }

  const [hours, minutes] = value.split(':');

  return (
    hours && minutes && isNumber(Number(hours)) && isNumber(Number(minutes))
  );
};

export const hoursAndMinutesToMs = value => {
  if (!isInHoursAndMinutesFormat(value)) {
    return undefined;
  }

  const [hours, minutes] = value.split(':');

  return (Number(hours) - 1) * 60 * 60 * 1000 + Number(minutes) * 60 * 1000;
};

export const msToHoursAndMinutes = value => {
  if (!value || !isNumber(value)) {
    return undefined;
  }

  return format(value, 'HH:mm');
};
