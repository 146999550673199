import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { excludeFromMap } from '../../utilities/data/object';
import DefaultSelectControl from './SelectControl';

const SwitchControl = props => {
  const {
    SelectControl,
    name,
    typeKey,
    options,
    value,
    onChange,
    flatten,
    isDisabled,
    ...rest
  } = props;

  const type = value ? value[typeKey] : undefined;
  const option = options[type];
  const Component = option ? option.Control : undefined;
  const selectOptions = Object.entries(options).reduce(
    (acc, [key, { label }]) => ({ ...acc, [key]: label }),
    {}
  );

  const handleSelectControlChange = option => {
    if ((value && value[typeKey]) !== option) {
      const rest = flatten.reduce(
        (acc, key) => ({ ...acc, [key]: undefined }),
        {}
      );
      onChange({ [typeKey]: option, ...rest });
    }
  };

  const handleControlChange = value =>
    onChange(
      option.flatten
        ? { [typeKey]: type, ...value }
        : { [typeKey]: type, [option.valueKey]: value }
    );

  return (
    <Fragment>
      <SelectControl
        name={`${name}.${typeKey}`}
        value={type}
        onChange={handleSelectControlChange}
        options={selectOptions}
        isDisabled={isDisabled}
      />
      {Component ? (
        <Component
          name={`${name}.${typeKey}`}
          value={
            option.flatten
              ? excludeFromMap(value, typeKey)
              : value[option.valueKey]
          }
          onChange={handleControlChange}
          {...rest}
        />
      ) : null}
    </Fragment>
  );
};

SwitchControl.defaultProps = {
  SelectControl: DefaultSelectControl,
  typeKey: 'type',
};

SwitchControl.propTypes = {
  SelectControl: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  typeKey: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SwitchControl;
