import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ControlError from '../errors/ControlError';
import { withTheme } from '../../theme';

const Option = styled.div`
  padding: 1rem;
  cursor: pointer;
  border: none;
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  margin: 0.1rem 0;

  transition: ${withTheme(theme => theme.transition)};

  background-color: ${withTheme((theme, props) =>
    props.selected
      ? theme.textColor
          .darken(0.1)
          .fade(0.8)
          .string()
      : theme.textColor
          .darken(0.1)
          .fade(0.95)
          .string()
  )};

  &:hover {
    background-color: ${withTheme(theme =>
      theme.textColor
        .darken(0.2)
        .fade(0.8)
        .string()
    )};
  }
`;

const ListPickerControl = ({
  Control = Option,
  Error = ControlError,
  options,
  value,
  onChange,
  isDisabled,
  getOptionValue,
  name = 'text',
  error,
  hasError,
}) => {
  return (
    <Fragment>
      <input
        readOnly={true}
        id={name}
        name={name}
        type="text"
        hidden={true}
        value={value || ''}
      />
      {options.map((option, index) => (
        <Control
          key={index}
          selected={getOptionValue(option.value) === getOptionValue(value)}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Control>
      ))}
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </Fragment>
  );
};

ListPickerControl.defaultProps = {
  options: [],
  getOptionValue: value => value,
};

ListPickerControl.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func,
};

export default ListPickerControl;
