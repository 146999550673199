import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FormError from '../errors/FormError';
import { isDefinedAndNotNull, isFunction } from '../../utilities/check';
import { excludeFromMap } from '../../utilities/data/object';

const generateKeyName = (keyName, name) =>
  `${keyName ? `${keyName}.` : ''}${name}`;

const generateUniqueName = (formId, name, keyName) =>
  `${formId}.${keyName ? `${keyName}.` : ''}${name}`;

const generateControlProps = (name, Component, index, props) => {
  const {
    formId,
    keyName,
    flatten,
    autoFocus,
    rootValue,
    value,
    initialValue,
    onChange,
    onKeyDown,
    disabled,
    error,
    ...rest
  } = props;

  const hasError = !!(error && isDefinedAndNotNull(error[name]));
  const inputValue = { ...initialValue, ...value };
  const uniqueName = generateUniqueName(formId, name, keyName);

  const handleKeyDown = (event, keyNameValue) => {
    if (isFunction(onKeyDown)) {
      onKeyDown(
        event,
        keyNameValue || generateKeyName(keyName, name),
        inputValue[name]
      );
    }
  };

  const handleChange = value =>
    flatten[name]
      ? onChange({ ...inputValue, ...value })
      : onChange({ ...inputValue, [name]: value });

  return {
    formId,
    valueKey: generateKeyName(keyName, name),
    name: uniqueName,
    key: uniqueName,
    autoFocus: !keyName && index === 0 && autoFocus,
    rootValue,
    value: flatten[name] ? inputValue : inputValue[name],
    onChange: handleChange,
    isDisabled: disabled,
    hasError,
    error: hasError ? error[name] : undefined,
    onKeyDown: isFunction(handleKeyDown) ? handleKeyDown : undefined,
    ...excludeFromMap(rest, ['name']),
  };
};

const ObjectControl = props => {
  const { formId, schema, error } = props;
  const hasError = !!(error && error['']);

  return (
    <Fragment>
      <FormError id={`form-${formId}`} isVisible={hasError}>
        {hasError ? error[''] : ''}
      </FormError>
      {Object.entries(schema).map(([name, Component], i) => {
        const inputProps = generateControlProps(name, Component, i, props);
        return <Component {...inputProps} />;
      })}
    </Fragment>
  );
};

ObjectControl.defaultProps = {
  flatten: {},
};

ObjectControl.propTypes = {
  formId: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  initialValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  flatten: PropTypes.object,
};

export default ObjectControl;
