import React, { Fragment } from 'react';

import Input from '../html/Input';

import ControlError from '../errors/ControlError';

export default ({
  Control = Input,
  Error = ControlError,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'email',
  error,
  hasError,
  ...rest
}) => (
  <Fragment>
    <Control
      id={name}
      type="email"
      name={name}
      autoComplete="email"
      autoCapitalize="off"
      autoCorrect="off"
      placeholder="e.g., dr.lawson@hospital.com"
      autoFocus={autoFocus || false}
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      disabled={isDisabled}
      hasError={hasError}
      {...rest}
    />
    <Error isVisible={hasError} id={name}>
      {hasError ? error : ''}
    </Error>
  </Fragment>
);
