import React, { Fragment } from 'react';
import styled from 'styled-components';
import { compose, withProps, withState } from 'recompose';

import {
  ExampleWrapper,
  Section,
  PageTitle,
  SectionTitle,
  Pre2,
  Code2,
} from '../common';
import SwitchControl from '../../lib/components/controls/SwitchControl';
import SyntaxHighlighter from '../../SyntaxHighlighter';
import SelectControl from '../../lib/components/controls/SelectControl';
import TextControl from '../../lib/components/controls/TextControl';
import { withLabel } from '../../lib/components/Label';
import { EmailControl, ObjectControl, PasswordControl } from '../../lib';
import NumberControl from '../../lib/components/controls/NumberControl';

const ThinWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 20rem;
`;

const CredentialsControl = withProps({
  schema: {
    email: EmailControl,
    password: PasswordControl,
  },
})(ObjectControl);

const TypeSwitchControl = compose(
  withProps({
    SelectControl: withLabel('Type')(SelectControl),
    options: {
      waitingRoom: {
        label: 'Weight',
        valueKey: 'weight',
        Control: withLabel('Number')(NumberControl),
      },
      operationRoom: {
        label: 'Name',
        valueKey: 'name',
        Control: withLabel('Text')(TextControl),
      },
      preparationRoom: {
        label: 'Credentials',
        valueKey: 'credentials',
        Control: CredentialsControl,
        flatten: true,
      },
    },
    typeKey: 'type',
    flatten: [],
  })
)(SwitchControl);

const TypeSwitchExample = withState('value', 'setValue')(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>Switch Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <TypeSwitchControl value={value} onChange={setValue} />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import SwitchControl from 'spicy-everything/lib/components/controls/SwitchControl';

<SwitchControl value={…} onChange={…} options={…} />`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

const ObjectExample = withState('value', 'setValue')(({ value, setValue }) => (
  <Section>
    <SectionTitle>Object Control</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <CredentialsControl value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import ObjectControl from 'spicy-everything';

const CredentialsControl = withProps({
  schema: {
    email: EmailControl,
    password: PasswordControl,
  },
})(ObjectControl);

<CredentialsControl value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
));

export default () => (
  <Fragment>
    <PageTitle>Complex Controls</PageTitle>
    <TypeSwitchExample />
    <ObjectExample />
  </Fragment>
);
