import React from 'react';
import styled from 'styled-components';
import ClickToSelect from '@mapbox/react-click-to-select';

import { H1, H2, Paragraph } from './lib';
import {
  Code,
  ExampleWrapper,
  Pre,
  PreInline,
  Section,
} from './examples/common';

import logo from './assets/images/logo.svg';
import spfrLogo from './assets/images/spfr_logo.svg';
import chestClosed from './assets/images/closed.png';
import chestOpen from './assets/images/open.gif';
import { withTheme } from './lib/theme';
import Link from './lib/components/Link';
import { compose, withState } from 'recompose';
import Button from './lib/components/Button';
import responsive from './lib/utilities/responsive';
import ButtonRouterLink from './lib/components/ButtonRouterLink';

const Hero = styled.div`
  margin-bottom: 5rem;

  ${responsive.md.andSmaller`
    margin-bottom: 2rem;
  `};
`;

const Container = styled.div`
  padding: 0 1rem;
  // width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  text-align: ${props => (props.center ? 'center' : 'auto')};
  display: ${props => (props.flex ? 'flex' : 'block')};
  flex-flow: ${props => (props.column ? 'column' : 'initial')};
`;

const Columns = styled(Container)`
  display: flex;
  margin: inherit -1rem;

  ${responsive.md.andSmaller`
    flex-flow: column;
  `};
`;

const contentAlignment = props => {
  if (props.center) return 'center';
  else if (props.right) return 'right';
  else return 'initial';
};

const Block = styled.div`
  flex: 1;
  margin: 0 1rem 1.5rem;
  text-align: ${props => contentAlignment(props)};

  ${responsive.sm.andSmaller`
    text-align: left;
  `};
`;

const Wrapper = styled.div`
  overflow-y: auto;
`;

const Image = styled.img`
  max-width: 24rem;

  ${responsive.sm.andSmaller`
    max-width: 12rem;
  `};
`;

const Footer = styled.footer`
  background-color: ${withTheme(theme =>
    theme.border.color.default.alpha(0.5).string()
  )};
  padding: 4rem 0;
`;

const NavLink = styled(Link)`
  margin-left: 1.5rem;
`;

const Navbar = styled.div`
  padding: 2rem 0;
`;

const Brand = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Logo = styled.img``;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const FooterLogo = styled.img`
  width: 8rem;
`;

const InlineCodeSnippet = ({ children, onClick }) => (
  <PreInline onClick={onClick}>
    <Code>{children}</Code>
  </PreInline>
);

const docsHomepage = '/inputs';

const Module = ({ image, setImage }) => {
  const changeImage = () => {
    setImage(chestOpen);
  };

  return (
    <Wrapper>
      <Hero>
        <Navbar>
          <Container flex>
            <Brand>
              <Logo src={logo} />
            </Brand>
            <Nav>
              <NavLink href={docsHomepage}>Docs</NavLink>
              <NavLink href="https://github.com/spfr/spicy-everything">
                Github
              </NavLink>
            </Nav>
          </Container>
        </Navbar>
        <Container center>
          <Image src={image} />
          <H1>Hottest UI components built with React</H1>
          <InlineCodeSnippet large onClick={changeImage}>
            <ClickToSelect>npm install --save spicy-everything</ClickToSelect>
          </InlineCodeSnippet>
          <Paragraph muted size={'s'}>
            v0.1.0
          </Paragraph>
        </Container>
      </Hero>
      <Section>
        <Columns>
          <Block>
            <H2>Installation</H2>
            <Paragraph>
              Include SpicyUI via npm or yarn. Package managed installs don’t
              include documentation, but do include our build system and readme.
            </Paragraph>
            <InlineCodeSnippet>
              <ClickToSelect>npm install --save spicy-everything</ClickToSelect>
            </InlineCodeSnippet>
            <Paragraph>or</Paragraph>
            <InlineCodeSnippet>
              <ClickToSelect>yarn add spicy-everything</ClickToSelect>
            </InlineCodeSnippet>
          </Block>
          <Block>
            <H2>Usage</H2>
            <Paragraph>
              Include SpicyUI via npm or yarn. Package managed installs don’t
              include documentation, but do include our build system and readme.
            </Paragraph>
            <ExampleWrapper>
              <Button primary onClick={() => alert('So hot.')}>
                Click me
              </Button>
            </ExampleWrapper>
            <Pre>
              <Code>
                {`import Button from 'spicy-everything/lib/components/Button';

<Button primary onClick={() => alert('So hot.')}>Click me</Button>`}
              </Code>
            </Pre>
            <Paragraph>
              <ButtonRouterLink ghost="true" to={docsHomepage}>
                Explore the Docs
              </ButtonRouterLink>
            </Paragraph>
          </Block>
        </Columns>
      </Section>
      <Footer>
        <Columns>
          <Block>© 2019 SpiceFactory, Llc.</Block>
          <Block center>
            <FooterLogo src={spfrLogo} alt="" />
          </Block>
          <Block right>
            <NavLink href="">Docs</NavLink>
            <NavLink href="">Github</NavLink>
          </Block>
        </Columns>
      </Footer>
    </Wrapper>
  );
};

export default compose(withState('image', 'setImage', chestClosed))(Module);
