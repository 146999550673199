import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { string } from '../utilities/random';
import { logError } from '../utilities/error';
import { isString } from '../utilities/check';
import { getNestedValue } from '../utilities/data/object';

import FormError from './errors/FormError';

const randomString = string();

export default class Form extends PureComponent {
  static propTypes = {
    input: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value:
        props.initialValue === undefined
          ? props.input.defaultValue
          : props.initialValue,
    };

    this.mounted = false;
    this.formId = randomString();
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = async value => {
    this.setState({
      hasError: false,
      error: undefined,
      errors: undefined,
    });

    try {
      this.setState({ value });
      await this.props.onSubmit(value);
    } catch (error) {
      logError(error);
      this.setState({
        hasError: true,
        error: getNestedValue('error', error),
        errors: getNestedValue('errors', error),
      });
    }
  };

  render() {
    const {
      input: Control = () => null,
      Error = FormError,
      autoFocus,
    } = this.props;
    const { value, hasError, error } = this.state;

    return (
      <Fragment>
        <Error isVisible={hasError} id={this.formId}>
          {isString(error)
            ? error
            : 'An unexpected error has occurred.\nPlease try, again or contact our support.'}
        </Error>
        <form noValidate>
          <Control
            autoFocus={autoFocus}
            rootValue={value}
            value={value}
            onChange={this.handleChange}
            error={error}
            formId={this.formId}
          />
        </form>
      </Fragment>
    );
  }
}
