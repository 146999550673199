import React from 'react';
import { compose, lifecycle } from 'recompose';

import { Paragraph } from '../lib';
import { Section, PageTitle, SectionTitle, Subtitle } from './common';
import DocumentationTable from '../DocumentationTable';
import InlineCode from '../InlineCode';
import RouterLink from '../lib/components/RouterLink';

const options = {
  1: 'Jalapeno',
  2: 'Habanero',
  3: 'Poblano',
};

export default () => (
  <Section>
    <PageTitle>Controls</PageTitle>
    <Subtitle>Use controls to allow user to manipulate a value.</Subtitle>
    <Paragraph>
      Control is a component which allows user to instantly edit a value (e.g.
      font size, email address). Value can be of any JavaScript type — string,
      integer, boolean, object, collection, etc.
    </Paragraph>
    <Paragraph>
      If you need to pass the value for further asynchronous processing upon
      certain event, use <RouterLink to="/forms">Forms.</RouterLink>
    </Paragraph>
    <Paragraph>
      Control components have to implement Control Component Interface.
    </Paragraph>
    <Section>
      <SectionTitle>Control Component Interface</SectionTitle>
      <Paragraph>
        Control Component Interface is a set of props which control component
        has to handle adequately.
      </Paragraph>
      <DocumentationTable>
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
            <th>Default Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InlineCode>value</InlineCode>
            </td>
            <td>Value for this control to represent.</td>
            <td>
              <InlineCode>undefined</InlineCode>
            </td>
          </tr>
          <tr>
            <td>
              <InlineCode>onChange</InlineCode>
            </td>
            <td>
              Function which accepts next value of this control. If the value
              provided is valid, it is expected that
              <InlineCode>value</InlineCode> prop will immediately change to
              that.
            </td>
            <td>
              <InlineCode>{'() => {}'}</InlineCode>
            </td>
          </tr>
        </tbody>
      </DocumentationTable>
    </Section>
  </Section>
);
