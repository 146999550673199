import React from 'react';
import styled from 'styled-components';
import responsive from '../utilities/responsive';

import { withTheme } from '../theme';

const Root = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) 4.5fr;
  overflow: hidden;
  background-color: ${withTheme(theme => theme.backgroundColor.string())};
  color: ${withTheme(theme => theme.textColor.string())};

  ${responsive.md.andSmaller`
    grid-template-columns: 0 4.5fr;
  `};
`;

const Placeholder = styled.div`
  grid-column-start: ${props => props.start};
  grid-column-end: ${props => props.end};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: auto;
  > * {
    flex: 1;
  }
`;

export default ({ Sidebar, children, className, styles, ...props }) =>
  Sidebar ? (
    <Root className={className} styles={styles}>
      <Placeholder start={1} end={1}>
        <Sidebar {...props} />
      </Placeholder>
      <Placeholder key="content" start={2} end={2}>
        {React.Children.only(children)}
      </Placeholder>
    </Root>
  ) : (
    <Root className={className} styles={styles}>
      <Placeholder key="content" start={1} end={2}>
        {React.Children.only(children)}
      </Placeholder>
    </Root>
  );
