import styled from 'styled-components';
import { withTheme } from '../../theme';

export default styled.textarea`
  background: ${withTheme(theme => theme.input.backgroundColor)};
  border-width: ${withTheme(theme => theme.border.width.default)};
  border-style: solid;
  border-color: ${withTheme((theme, props) =>
    props.hasError
      ? theme.border.color.warning.string()
      : props.isTransparent
      ? 'transparent'
      : theme.border.color.default.string()
  )};
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  color: ${withTheme(theme => theme.textColor.string())};
  padding: ${withTheme(theme => theme.input.padding)};
  transition: ${withTheme(theme => theme.transition)};
  font-size: ${withTheme(theme => theme.textSize.m)};
  line-height: ${withTheme(theme => theme.lineHeight.xs)};

  &::placeholder {
    color: ${withTheme(theme => theme.textColor.string())};
    opacity: 0.5;
  }

  &:focus {
    border-color: ${withTheme(theme => theme.border.color.focus.string())};
    outline: none;
  }

  :disabled,
  &[disabled] {
    opacity: ${withTheme(theme => theme.input.disabled.opacity)};
    cursor: default;
    pointer-events: none;
  }
`;
