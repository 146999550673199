import styled from 'styled-components';

export default styled.div`
  display: grid;

  align-items: stretch;
  justify-items: stretch;

  > * {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
`;
