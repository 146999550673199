import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash.clonedeep';
import set from 'lodash.set';
import styled from 'styled-components';

import { string } from '../utilities/random';
import { logError } from '../utilities/error';
import { isFunction, isString } from '../utilities/check';
import { getNestedValue } from '../utilities/data/object';

import Button from './Button';
import FormError from './errors/FormError';

const randomString = string();

const HtmlForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export default class Form extends PureComponent {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    Footer: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value:
        props.initialValue === undefined
          ? props.input.defaultValue
          : props.initialValue,
      busy: false,
      isDirty: false,
    };

    this.mounted = false;
    this.formId = randomString();
    this.originalValue = clone(this.state.value);

    this.keyCodeControlHandlers = {
      27: this.handleControlEscKeyEvent,
    };
  }

  handleControlEscKeyEvent = (event, keyPath) => {
    const value = { ...this.state.value };
    set(value, keyPath, getNestedValue(keyPath, this.originalValue));
    this.handleChange(value);
    event.target.blur();
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({
      busy: true,
      hasError: false,
      error: undefined,
      errors: undefined,
    });

    try {
      await this.props.onSubmit(this.state.value);
      this.originalValue = clone(this.state.value);
      if (this.mounted) {
        this.setState({ isDirty: false });
      }
    } catch (error) {
      logError(error);

      const getFirst = values => {
        if (values && values.length > 0) {
          return values[0].message;
        }
      };

      const errors = getNestedValue('error', error);

      this.setState({
        busy: false,
        hasError: true,
        error: getFirst(errors),
        errors: errors,
      });
    } finally {
      if (this.mounted) {
        this.setState({ busy: false });
      }
    }
  };

  handleChange = value => {
    const isDirty =
      JSON.stringify(value) !== JSON.stringify(this.originalValue);
    this.setState({ value, isDirty });
  };

  handleOnBusy = value => this.setState({ busy: value });
  handleOnError = ({ hasError, error, errors }) => {
    this.setState({ hasError, error, errors });
  };

  handleControlKeyDown = (event, ...rest) => {
    const handler = this.keyCodeControlHandlers[event.keyCode];
    if (isFunction(handler)) {
      handler(event, ...rest);
    }
  };

  render() {
    const {
      input: Control = () => null,
      Footer,
      Error = FormError,
      autoFocus,
      label,
      isTransparent,
    } = this.props;
    const { value, busy, isDirty, hasError, error } = this.state;

    return (
      <Fragment>
        <Error isVisible={hasError} id={this.formId}>
          {isString(error)
            ? error
            : 'An unexpected error has occurred.\nPlease, try again or contact our support.'}
        </Error>
        <HtmlForm onSubmit={this.handleSubmit}>
          <Control
            autoFocus={autoFocus}
            rootValue={value}
            value={value}
            onChange={this.handleChange}
            disabled={busy}
            error={error}
            formId={this.formId}
            isDirty={isDirty}
            isTransparent={isTransparent}
            onKeyDown={this.handleControlKeyDown}
          />
          {Footer ? (
            <Footer
              {...this.props}
              busy={busy}
              isDirty={isDirty}
              onBusy={this.handleOnBusy}
              onError={this.handleOnError}
              isTransparent={isTransparent}
            />
          ) : (
            <p>
              <Button primary busy={busy}>
                {label}
              </Button>
            </p>
          )}
        </HtmlForm>
      </Fragment>
    );
  }
}
