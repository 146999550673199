import styled from 'styled-components';

import { withTheme } from '../../theme';

export const linkType = props => {
  if (props.danger) {
    return 'dangerColor';
  } else {
    return 'primaryColor';
  }
};

export default styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  color: ${withTheme((theme, props) => theme.link[linkType(props)].string())};
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  border-bottom: 1px dotted
    ${withTheme((theme, props) =>
      theme.link.underlined
        ? theme.link[linkType(props)].string()
        : 'transparent'
    )};
  transition: color 150ms ease-in-out;
  text-decoration: none;

  :hover {
    color: ${withTheme((theme, props) =>
      theme.link[linkType(props)]
        .desaturate(0.1)
        .darken(0.1)
        .string()
    )};
    border-color: ${withTheme((theme, props) =>
      theme.link.underlined
        ? theme.link[linkType(props)]
            .desaturate(0.1)
            .darken(0.1)
            .string()
        : 'transparent'
    )};
  }

  :active {
    color: ${withTheme((theme, props) =>
      theme.link[linkType(props)]
        .desaturate(0.1)
        .darken(0.2)
        .string()
    )};
    border-color: ${withTheme((theme, props) =>
      theme.link.underlined
        ? theme.link[linkType(props)]
            .desaturate(0.1)
            .darken(0.2)
            .string()
        : 'transparent'
    )};
  }

  :disabled,
  &[disabled] {
    background-color: transparent;
    opacity: ${withTheme(theme => theme.link.disabled.opacity)};
    cursor: default;
    pointer-events: none;
  }
`;
