import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route, Router } from 'react-router-dom';
import { compose, withProps, withState } from 'recompose';
import createHistory from 'history/createBrowserHistory';
import styled, { ThemeProvider } from 'styled-components';

import { ResetStyles, Page, Sidebar, responsive } from './lib';
import { Header as SidebarHeader } from './lib/components/Sidebar';

import ControlsExample from './examples/Controls';
import FormsExample from './examples/Forms';
import ButtonsExample from './examples/Buttons';
import LinksExample from './examples/Links';

import ControlLabelExample from './examples/controls/ControlLabel';
import InputControlExamples from './examples/controls/InputControls';
import PickerControlExamples from './examples/controls/PickerControls';
import ComplexControlExamples from './examples/controls/ComplexControls';

import GlobalStyles from './GlobalStyles';
import Logo from './Logo';
import ThemeSelectControl from './ThemeSelectControl';
import themes from './themes';
import Homepage from './Homepage';
import RouterLink from './lib/components/RouterLink';

const Content = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;

  ${responsive.md.andSmaller`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
`;

const SidebarFooter = styled.div`
  padding: calc(2 * ${props => props.theme.defaultSpacing});
`;

const Copyright = styled.span`
  display: block;
  font-size: 0.75em;
  opacity: 0.45;
`;

const inputPages = [
  { path: '/label', title: 'Control Label', component: ControlLabelExample },
  { path: '/inputs', title: 'Input Controls', component: InputControlExamples },
  {
    path: '/pickers',
    title: 'Picker Controls',
    component: PickerControlExamples,
  },
  {
    path: '/complex',
    title: 'Complex Controls',
    component: ComplexControlExamples,
  },
];

const topLevelPages = [
  // { path: '/typography', title: 'Typography', component: TypographyExample },
  {
    path: '/inputs',
    title: 'Controls',
    component: ControlsExample,
    subPages: inputPages,
  },
  { path: '/forms', title: 'Forms', component: FormsExample },
  // { path: '/lists', title: 'Lists', component: ListsExample },
  // { path: '/table', title: 'Table', component: TableExample },
  // { path: '/Crud', title: 'CRUD', component: CrudExample },
  // { path: '/layouts', title: 'Layouts', component: LayoutsExample },
  {
    path: '/buttons',
    title: 'Buttons',
    component: ButtonsExample,
  },
  {
    path: '/links',
    title: 'Links',
    component: LinksExample,
  },
  // { path: '/application-states', title: 'Application States', component: ApplicationStates },
];

const ApplicationSidebar = withProps({
  Header: () => (
    <RouterLink to="/" style={{ display: 'block' }}>
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
    </RouterLink>
  ),
  Footer: ({ theme, setTheme }) => (
    <SidebarFooter>
      <ThemeSelectControl value={theme} onChange={option => setTheme(option)} />
      <Copyright>Copyright © 2019 SpiceFactory</Copyright>
    </SidebarFooter>
  ),
  links: topLevelPages.map(({ path, title, subPages }) => ({
    to: path,
    label: title,
    subPages:
      subPages &&
      subPages.map(subPage => ({
        to: `${path}${subPage.path}`,
        label: subPage.title,
      })),
  })),
})(Sidebar);

const Website = ({ history, theme, setTheme }) => (
  <Fragment>
    <ResetStyles />
    <GlobalStyles />
    <Router history={history}>
      <ThemeProvider theme={themes[theme].theme}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Page Sidebar={ApplicationSidebar} theme={theme} setTheme={setTheme}>
            <Content>
              <Switch>
                {topLevelPages.map(({ path, component, subPages }) => [
                  <Route key={path} exact path={path} component={component} />,
                  ...(subPages
                    ? subPages.map(subPage => (
                        <Route
                          key={`${path}${subPage.path}`}
                          path={`${path}${subPage.path}`}
                          component={subPage.component}
                        />
                      ))
                    : []),
                ])}
                <Route render={() => <Redirect to="/inputs" />} />
              </Switch>
            </Content>
          </Page>
        </Switch>
      </ThemeProvider>
    </Router>
  </Fragment>
);

export default compose(
  withProps({ history: createHistory() }),
  withState('theme', 'setTheme', 'light')
)(Website);
