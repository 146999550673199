import styled from 'styled-components';
import { withTheme } from './lib/theme';

export default styled.table`
  th,
  td {
    padding: 1em;
    text-align: left;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  thead {
    font-weight: bold;
    color: ${withTheme(theme => theme.textColor.lighten(5).string())};
    white-space: nowrap;
  }
  tbody > tr {
    border-top: 2px solid
      ${withTheme(theme => theme.border.color.default.string())};
  }
`;
