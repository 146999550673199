import React from 'react';
import styled from 'styled-components';

import { Text } from './typography';

const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const DescriptionContent = styled.label`
  margin-left: 0.15rem;
`;

const Description = ({ text, children, size = 's' }) => (
  <DescriptionContainer>
    {children}
    <DescriptionContent>
      <Text size={size} wrap>
        {text}
      </Text>
    </DescriptionContent>
  </DescriptionContainer>
);

export default Description;

export const withDescription = (
  description,
  ...descriptionProps
) => Component => props => (
  <Description {...descriptionProps} text={description}>
    <Component {...props} />
  </Description>
);
