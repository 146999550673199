import React, { Fragment } from 'react';

import Input from '../html/Input';
import ControlError from '../errors/ControlError';

export default ({
  Control = Input,
  Error = ControlError,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'text',
  error,
  hasError,
  htmlType,
  ...rest
}) => (
  <Fragment>
    <Control
      id={name}
      type={isHidden ? 'hidden' : htmlType ? htmlType : 'text'}
      name={name}
      autoComplete="on"
      autoCapitalize="off"
      autoCorrect="off"
      placeholder={placeholder}
      autoFocus={autoFocus || false}
      value={value || ''}
      checked={!!value}
      onChange={e =>
        onChange(htmlType === 'checkbox' ? e.target.checked : e.target.value)
      }
      disabled={isDisabled}
      hasError={hasError}
      {...rest}
    />
    <Error isVisible={hasError} id={name}>
      {hasError ? error : ''}
    </Error>
  </Fragment>
);
