import React, { Fragment } from 'react';
import { Link, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import { withTheme } from '../theme';
import responsive from '../utilities/responsive';
import { withRouter } from 'react-router';

const Container = styled.aside`
  display: flex;
  flex-direction: column;

  ${responsive.md.andSmaller`
    position: fixed;
    width: 100vw;
    height: 100vw;
    transform: translateX(-100vw);
  `};
`;

const Top = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: calc(2 * ${withTheme(theme => theme.defaultSpacing)});
  justify-content: center;
  font-weight: 500;
  font-size: 1.75rem;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div``;

const LinkContainer = styled(Link)`
  display: flex;
  padding: calc(0.5 * ${withTheme(theme => theme.defaultSpacing)})
    calc(2 * ${withTheme(theme => theme.defaultSpacing)});
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${withTheme(theme => theme.sidebar.textColor.string())};
  ${props => (props.highlighted ? '> div { font-weight: 700; }' : '')};
  font-size: 1.25rem;
  transition: ${withTheme(theme => theme.transition)};
  white-space: nowrap;

  &:hover {
  }
`;

const Icon = styled.i`
  opacity: 0.3;
  transition: opacity 0.1s ease;
  margin-right: 0.5rem;
`;

const Text = styled.div`
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
  transition: opacity 0.1s ease;
  white-space: nowrap;

  ${LinkContainer}:hover & {
    opacity: 1;
  }
`;

const Separator = styled.div`
  background-color: ${withTheme(theme => theme.textColor.alpha(0.08).string())};
  height: 2px;
  width: calc(100% - 4 * ${withTheme(theme => theme.defaultSpacing)});
  margin: calc(0.75 * ${withTheme(theme => theme.defaultSpacing)})
    calc(2 * ${withTheme(theme => theme.defaultSpacing)});
`;

export const Header = ({ children }) => (
  <HeaderContainer>{children}</HeaderContainer>
);

const SidebarLink = ({ icon, children, ...rest }) => {
  return (
    <LinkContainer {...rest}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      <Text>{children}</Text>
    </LinkContainer>
  );
};

const SubLinkContainer = styled(LinkContainer)`
  > ${Text} {
    font-size: 0.625em;
    padding-left: 1em;
  }

  &:hover > ${Text} {
    opacity: 1;
  }
`;

const SidebarSubLink = ({ icon, children, ...rest }) => {
  return (
    <SubLinkContainer {...rest}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      <Text>{children}</Text>
    </SubLinkContainer>
  );
};

const Sidebar = ({
  location,
  Header,
  Footer,
  links,
  classNames,
  styles,
  ...props
}) => (
  <Container classNames={classNames} styles={styles}>
    <Top>
      {Header && <Header {...props} />}
      {links && (
        <Links key="links">
          {links.map(
            ({ to, label, subPages, icon, target, separator }, index) => (
              <Fragment key={to}>
                {separator ? (
                  <Separator key={index} />
                ) : (
                  <SidebarLink
                    key={to}
                    to={to}
                    icon={icon}
                    target={target}
                    highlighted={
                      !!matchPath(location.pathname, {
                        path: to,
                        exact: true,
                        strict: false,
                      })
                        ? 'true'
                        : undefined
                    }
                  >
                    {label}
                  </SidebarLink>
                )}
                {subPages &&
                  matchPath(location.pathname, {
                    path: to,
                    exact: false,
                    strict: false,
                  }) &&
                  subPages.map(({ to, label }) => (
                    <SidebarSubLink
                      key={to}
                      to={to}
                      highlighted={
                        !!matchPath(location.pathname, {
                          path: to,
                          exact: true,
                          strict: false,
                        })
                          ? 'true'
                          : undefined
                      }
                    >
                      {label}
                    </SidebarSubLink>
                  ))}
              </Fragment>
            )
          )}
        </Links>
      )}
    </Top>
    {Footer && (
      <Bottom>
        <Footer {...props} />
      </Bottom>
    )}
  </Container>
);
export default withRouter(Sidebar);
