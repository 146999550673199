import styled from 'styled-components';

import { withTheme } from '../../theme';

export const buttonType = props => {
  if (props.danger) {
    return 'danger';
  } else if (props.primary) {
    return 'primary';
  } else if (props.ghost) {
    return 'ghost';
  } else {
    return 'default';
  }
};

export const buttonSize = props => {
  if (props.xl) {
    return 'xl';
  } else if (props.l) {
    return 'l';
  } else if (props.s) {
    return 's';
  } else if (props.xs) {
    return 'xs';
  } else {
    return 'm';
  }
};

export default styled.button`
  position: relative;
  display: inline-block;
  font-size: ${withTheme((theme, props) => theme.textSize[buttonSize(props)]) ||
    'inherit'};
  font-weight: 500;
  font-family: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  line-height: 1;
  outline: none;
  background-color: ${withTheme((theme, props) =>
    theme.button[buttonType(props)].backgroundColor.string()
  )};
  color: ${withTheme((theme, props) =>
    props.busy ? 'transparent' : theme.button[buttonType(props)].color.string()
  )};
  border: ${withTheme(
    (theme, props) => theme.button[buttonType(props)].border
  )};
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  padding: 0.75em 1.25em;
  transition: all 0.1s ease;

  &:hover {
    cursor: pointer;
    background-color: ${withTheme((theme, props) =>
      theme.button[buttonType(props)].backgroundColor
        .desaturate(0.1)
        .darken(0.1)
        .string()
    )};
  }

  &:active {
    background-color: ${withTheme((theme, props) =>
      theme.button[buttonType(props)].backgroundColor
        .desaturate(0.2)
        .darken(0.2)
        .string()
    )};
  }

  :disabled,
  &[disabled] {
    background-color: ${withTheme((theme, props) =>
      theme.button[buttonType(props)].backgroundColor.string()
    )};
    opacity: ${withTheme(
      (theme, props) => theme.button[buttonType(props)].disabled.opacity
    )};
    cursor: default;
    pointer-events: none;
  }
`;
