import React from 'react';

import HtmlButton, { buttonType } from './html/Button';
import { AbsoluteSpinner } from './Spinner';

/**
 * Good old `<button>`.
 */
export default ({ children, busy, disabled, ...rest }) => {
  return (
    <HtmlButton {...rest} disabled={busy || disabled} busy={busy}>
      {children}
      {busy ? (
        <AbsoluteSpinner
          strokeAccessor={`button.${buttonType(rest)}.color`}
          {...rest}
        />
      ) : null}
    </HtmlButton>
  );
};
