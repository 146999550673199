import React from 'react';
import styled from 'styled-components';
import { compose, withState } from 'recompose';

import {
  ExampleWrapper,
  Section,
  PageTitle,
  Pre2,
  Code2,
  Subtitle,
} from '../common';
import EmailControl from '../../lib/components/controls/EmailControl';
import { withLabel } from '../../lib/components/Label';
import SyntaxHighlighter from '../../SyntaxHighlighter';
import { Paragraph } from '../../lib';

const ThinWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 20rem;
`;

const EmailAddress = withLabel('Email Address')(EmailControl);

const Example = ({ value, setValue }) => (
  <Section>
    <PageTitle>Control Label</PageTitle>
    <Subtitle>Add labels to controls using HoC.</Subtitle>
    <Paragraph>
      Controls come without labels. One way to provide it with label is to use
      following higher order component.
    </Paragraph>
    <Paragraph>Make sure to pass the name to the control.</Paragraph>
    <ExampleWrapper>
      <ThinWrapper>
        <EmailAddress name="email" value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import EmailControl from 'spicy-everything/lib/components/controls/EmailControl';
import { withLabel } from 'spicy-everything/lib/components/Label';

const EmailAddress = withLabel('Email Address')(EmailControl);

<EmailAddress name="email" value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
);

export default compose(withState('value', 'setValue'))(Example);
