import React, { Fragment } from 'react';
import styled from 'styled-components';
import { compose, withState } from 'recompose';

import {
  ExampleWrapper,
  Section,
  PageTitle,
  SectionTitle,
  Pre2,
  Code2,
} from '../common';
import EmailControl from '../../lib/components/controls/EmailControl';
import SyntaxHighlighter from '../../SyntaxHighlighter';
import TextAreaControl from '../../lib/components/controls/TextAreaControl';
import TimeControl from '../../lib/components/controls/TimeControl';
import PhoneControl from '../../lib/components/controls/PhoneControl';
import TextControl from '../../lib/components/controls/TextControl';
import NumberControl from '../../lib/components/controls/NumberControl';
import PasswordControl from '../../lib/components/controls/PasswordControl';

const ThinWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 20rem;
`;

const EmailExample = withState('value', 'setValue')(({ value, setValue }) => (
  <Section>
    <SectionTitle>Email Control</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <EmailControl name="email" value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import EmailControl from 'spicy-everything/lib/components/controls/EmailControl';

<EmailControl value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
));

const NumberExample = compose(withState('value', 'setValue'))(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>Number Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <NumberControl name="number" value={value} onChange={setValue} />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import NumberControl from 'spicy-everything/lib/components/controls/NumberControl';

<NumberControl value={…} onChange={…} />`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

const PasswordExample = withState('value', 'setValue')(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>Password Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <PasswordControl name="password" value={value} onChange={setValue} />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import PasswordControl from 'spicy-everything/lib/components/controls/PasswordControl';

<PasswordControl value={…} onChange={…} />`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

const PhoneExample = withState('value', 'setValue')(({ value, setValue }) => (
  <Section>
    <SectionTitle>Phone Control</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <PhoneControl name="phone" value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import PhoneControl from 'spicy-everything/lib/components/controls/PhoneControl';

<PhoneControl value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
));

const TextExample = withState('value', 'setValue')(({ value, setValue }) => (
  <Section>
    <SectionTitle>Text Control</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <TextControl name="text" value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import TextControl from 'spicy-everything/lib/components/controls/TextControl';

<TextControl value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
));

const TextAreaExample = withState('value', 'setValue')(
  ({ value, setValue }) => (
    <Section>
      <SectionTitle>TextArea Control</SectionTitle>
      <ExampleWrapper>
        <ThinWrapper>
          <TextAreaControl
            name="text-area"
            value={value}
            onChange={setValue}
            rows={5}
          />
        </ThinWrapper>
      </ExampleWrapper>
      <Pre2>
        <Code2>
          <SyntaxHighlighter>
            {`import TextAreaControl from 'spicy-everything/lib/components/controls/TextAreaControl';

<TextAreaControl value={…} onChange={…} rows={5} />`}
          </SyntaxHighlighter>
        </Code2>
      </Pre2>
    </Section>
  )
);

const TimeExample = withState('value', 'setValue')(({ value, setValue }) => (
  <Section>
    <SectionTitle>Time Control</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <TimeControl name="time" value={value} onChange={setValue} />
      </ThinWrapper>
    </ExampleWrapper>
    <Pre2>
      <Code2>
        <SyntaxHighlighter>
          {`import TimeControl from 'spicy-everything/lib/components/controls/TimeControl';

<TimeControl value={…} onChange={…} />`}
        </SyntaxHighlighter>
      </Code2>
    </Pre2>
  </Section>
));

export default () => (
  <Fragment>
    <PageTitle>Input Controls</PageTitle>
    <EmailExample />
    <NumberExample />
    <PasswordExample />
    <PhoneExample />
    <TextExample />
    <TextAreaExample />
    <TimeExample />
  </Fragment>
);
