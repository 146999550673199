import styled from 'styled-components';

export default styled.code`
  white-space: nowrap;
  background: rgb(95%, 95%, 95%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.25em 0.5em;
  font-family: monospace;
  border-radius: 0.25em;
  line-height: calc(1.5em + 2px);
`;
