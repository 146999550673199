import { createGlobalStyle } from 'styled-components';
import { withTheme } from './lib/theme';

export default createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    color: ${withTheme((theme, props) => theme.textColor.string())};
    overflow: hidden;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }
  
  #root {
    width: 100%;
    height: 100%;
    
    display: grid;
    align-items: stretch;
    justify-items: stretch;
    
    > * {
      display: grid;
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
  }
`;
