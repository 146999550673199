import color from 'color';

/**
 * Values to override in default theme to make it light.
 */
export const light = {};

/**
 * Values to override in default theme to make it dark.
 */
export const dark = {
  backgroundColor: color('#000C3F'),
  textColor: color('#E6ECEF'),
  border: {
    color: {
      default: color('#2F375A'),
    },
  },
  button: {
    default: {
      backgroundColor: color('#1a2553'),
      color: color('#E6ECEF'),
    },
  },
  sidebar: {
    backgroundColor: color('#000C3F'),
    textColor: color('#E6ECEF'),
  },
};

/**
 * Values to override in default theme to make it vibrant.
 */
export const vibrant = {
  backgroundColor: color('#1B7DF0'),
  textColor: color('white'),
  border: {
    color: {
      default: color('#328af2'),
      focus: color('#fff'),
    },
  },
  button: {
    default: {
      backgroundColor: color('#328af2'),
      color: color('#fff'),
    },
    primary: {
      backgroundColor: color('#fff'),
      color: color('#19549a'),
    },
    danger: {
      backgroundColor: color('#ff9999'),
      color: color('#b13030'),
    },
  },
  link: {
    primaryColor: color('#fff'),
    dangerColor: color('#ff9999'),
    underlined: true,
  },
  sidebar: {
    textColor: color('#000c3f'),
  },
};

export default {
  defaultSpacing: '1rem',
};
