import { withProps } from 'recompose';

import ObjectControl from '../../lib/components/controls/ObjectControl';
import EmailControl from '../../lib/components/controls/EmailControl';
import PasswordControl from '../../lib/components/controls/PasswordControl';
import { withLabel } from '../../lib/components/Label';

export default withProps({
  schema: {
    email: withLabel('Email Address')(EmailControl),
    password: withLabel('Password')(PasswordControl),
  },
})(ObjectControl);
