export const logError = error => {
  const devEnv = process.env.NODE_ENV !== 'production';

  if (devEnv) {
    console.error(error);

    try {
      console.error('JSON.stringify(error)', JSON.stringify(error));
    } catch (error) {
      console.log(`Unable to stringify an error`);
    }
  }
};
