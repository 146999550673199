import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from './typography';
import { isDefinedAndNotNull } from '../utilities/check';

const VanishingText = styled(Text)`
  ${props =>
    !props.hasValue &&
    css`
      display: none;
    `};
`;

const ControlContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 1.5rem;

  :last-child {
    margin-bottom: 0;
  }
`;

const FieldLabel = styled.label`
  margin-bottom: 0.75em;
  font-size: 0.937em;
`;

const Label = ({ text, children, ...rest }) => (
  <ControlContainer>
    <FieldLabel {...rest}>
      <VanishingText hasValue={isDefinedAndNotNull(text)}>{text}</VanishingText>
    </FieldLabel>
    {children}
  </ControlContainer>
);

export default Label;

export const withLabel = (label, labelProps = {}) => Component => ({
  name,
  ...rest
}) => {
  return (
    <Label {...labelProps} text={label} htmlFor={name}>
      <Component {...rest} name={name} />
    </Label>
  );
};
