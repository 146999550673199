import React, { Fragment } from 'react';

import Input from '../html/Input';
import ControlError from '../errors/ControlError';
import { isNumber } from '../../utilities/check';

export default ({
  Control = Input,
  Error = ControlError,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'number',
  error,
  hasError,
  ...rest
}) => (
  <Fragment>
    <Control
      id={name}
      type={isHidden ? 'hidden' : 'number'}
      name={name}
      autoComplete="on"
      autoCapitalize="off"
      autoCorrect="off"
      placeholder={placeholder}
      autoFocus={autoFocus || false}
      value={value || ''}
      onChange={e => {
        const value = parseFloat(e.target.value);

        return isNumber(value) && !isNaN(value)
          ? onChange(value)
          : onChange(undefined);
      }}
      disabled={isDisabled}
      hasError={hasError}
      {...rest}
    />
    <Error isVisible={hasError} id={name}>
      {hasError ? error : ''}
    </Error>
  </Fragment>
);
