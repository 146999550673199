import React from 'react';
import { compose, withProps } from 'recompose';
import faker from 'faker';

import Form from '../lib/components/Form';
import AutoSubmittingForm from '../lib/components/AutoSubmittingForm';
import SubmitAndDangerLinkButtonForm from '../lib/components/forms/SubmitAndDangerLinkButtonForm';
import CredentialsControl from './controls/CredentialsControl';
import {
  Section,
  PageTitle,
  ExampleWrapper,
  SectionTitle,
  Subtitle,
} from './common';
import ObjectControl from '../lib/components/controls/ObjectControl';
import TextControl from '../lib/components/controls/TextControl';
import NumberControl from '../lib/components/controls/NumberControl';
import PhoneControl from '../lib/components/controls/PhoneControl';
import SelectControl from '../lib/components/controls/SelectControl';
import SwitchControl from '../lib/components/controls/SwitchControl';
import { withLabel } from '../lib/components/Label';
import { withDescription } from '../lib/components/Description';
import { delay, optional, promise } from '../lib/utilities/random';
import { Paragraph, Code } from '../lib/components/typography';
import styled from 'styled-components';

const randomLoremText = optional(() => faker.lorem.text());
const randomLoremSentence = optional(() => faker.lorem.sentence());

const fakeEverythingError = optional(
  () => ({
    error: {
      '': randomLoremText(),
      email: randomLoremSentence(),
      password: randomLoremSentence(),
      name: randomLoremSentence(),
      select: randomLoremSentence(),
      number: randomLoremSentence(),
    },
  }),
  70
);

const randomPromise = promise(() => true, fakeEverythingError);

const NameControl = compose(
  withLabel(faker.name.jobType()),
  withProps({
    placeholder: `e.g., ${faker.name.findName()}`,
  })
)(TextControl);

const CreateNameControl = () =>
  withProps({
    schema: {
      name: NameControl,
    },
  })(ObjectControl);

const ErrorEverythingControl = withProps({
  schema: {
    name: NameControl,
    select: compose(
      withLabel(faker.name.jobType()),
      withProps({ placeholder: `e.g., ${faker.name.findName()}` })
    )(SelectControl),
    number: compose(
      withLabel(faker.name.jobType()),
      withProps({ placeholder: `e.g., ${faker.name.findName()}` }),
      withDescription(faker.hacker.phrase())
    )(NumberControl),
  },
})(ObjectControl);

const UpdateDeleteControl = CreateNameControl();

const NestedControls = withProps({
  schema: {
    name: NameControl,
    human: withProps({
      schema: {
        height: compose(
          withLabel('Height'),
          withProps({ placeholder: `e.g., ${faker.commerce.color()}` })
        )(NumberControl),
        eye: withProps({
          schema: {
            color: compose(
              withLabel('Color'),
              withProps({
                required: true,
                placeholder: `e.g., ${faker.commerce.color()}`,
                options: {
                  1: 'Red',
                  2: 'Blue',
                },
              })
            )(SelectControl),
          },
        })(ObjectControl),
        phone: compose(
          withLabel('Phone Number'),
          withProps({ placeholder: `e.g., ${faker.phone.phoneNumberFormat()}` })
        )(PhoneControl),
      },
    })(ObjectControl),
  },
})(ObjectControl);

const TypeSelectControl = compose(
  withLabel('Room Type'),
  withProps({ placeholder: 'e.g., Waiting Room' })
)(SelectControl);

const ExpectedDurationControl = compose(
  withLabel('Expected Duration'),
  withProps({
    placeholder: `e.g. 01:30`,
  })
)(TextControl);

const DailyCapacityInHoursControl = compose(
  withLabel('Daily capacity in hours'),
  withProps({
    placeholder: `e.g., 12`,
  })
)(NumberControl);

const PatientCapacityControl = compose(
  withLabel('Patient capacity'),
  withProps({
    placeholder: `e.g. 25`,
  })
)(NumberControl);

const WaitingRoomControl = ExpectedDurationControl;
const OperationRoomControl = DailyCapacityInHoursControl;
const PreparationRoomControl = withProps({
  schema: {
    capacity: PatientCapacityControl,
    expectedDuration: ExpectedDurationControl,
  },
})(ObjectControl);

const TypeSwitchControl = compose(
  withProps({
    SelectControl: TypeSelectControl,
    options: {
      waitingRoom: {
        label: 'Waiting Room',
        valueKey: 'expectedDuration',
        Control: WaitingRoomControl,
      },
      operationRoom: {
        label: 'Operation Room',
        valueKey: 'availability',
        Control: OperationRoomControl,
      },
      preparationRoom: {
        label: 'Preparation Room',
        valueKey: 'preparationRoom',
        Control: PreparationRoomControl,
        flatten: true,
      },
    },
    typeKey: 'type',
    flatten: ['expectedDuration', 'capacity', 'availability'],
  })
)(SwitchControl);

const SwitchControls = withProps({
  schema: {
    name: NameControl,
    typeSwitch: TypeSwitchControl,
  },
  flatten: {
    typeSwitch: true,
  },
})(ObjectControl);

const ThinWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 20rem;
`;

export default () => (
  <Section>
    <PageTitle>Forms</PageTitle>
    <Subtitle>
      Use forms when performing asynchronous operations which may fail.
    </Subtitle>
    <Paragraph>
      Forms are components which represent parametrized operations triggered
      upon certain events. For instance, there are forms which perform
      operations when users explicitly presses submit button, and there are
      forms which are automatically submitted after period of inactivity if they
      are not pristine.
    </Paragraph>
    <SectionTitle>Login</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <Form
          input={CredentialsControl}
          label={'Log in'}
          onSubmit={randomPromise}
        />
      </ThinWrapper>
    </ExampleWrapper>

    <SectionTitle>Update / Delete</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <SubmitAndDangerLinkButtonForm
          input={UpdateDeleteControl}
          label={'Update'}
          dangerLabel={'Delete'}
          onDangerLinkButtonClick={randomPromise}
          onSubmit={randomPromise}
          initialValue={{ name: faker.name.findName() }}
        />
      </ThinWrapper>
    </ExampleWrapper>

    <SectionTitle>Nested Controls</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <Form
          input={NestedControls}
          label={'Create'}
          onSubmit={randomPromise}
        />
      </ThinWrapper>
    </ExampleWrapper>

    <SectionTitle>Switch Controls</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <Form
          input={SwitchControls}
          label={'Create'}
          onSubmit={data => console.log('Form', data) || randomPromise()}
        />
      </ThinWrapper>
    </ExampleWrapper>

    <SectionTitle>Auto Submitting Form</SectionTitle>
    <ExampleWrapper>
      <ThinWrapper>
        <AutoSubmittingForm
          input={SwitchControls}
          onSubmit={data =>
            console.log('Auto Submitting Form', data) || randomPromise()
          }
        />
      </ThinWrapper>
    </ExampleWrapper>

    <SectionTitle>Server Validation Errors</SectionTitle>
    <Paragraph>
      This example will randomly generate errors based on a specific format.
      That may include/exclude some of the properties. <br />
      But keep pressing that <Code>Create</Code> button to get various types of
      errors. All inputs should be tested here with validation errors.
    </Paragraph>
    <ExampleWrapper>
      <ThinWrapper>
        <Form
          input={ErrorEverythingControl}
          label={'Create'}
          onSubmit={() =>
            new Promise((_, reject) =>
              delay(() => reject(fakeEverythingError()))
            )
          }
        />
      </ThinWrapper>
    </ExampleWrapper>
  </Section>
);
