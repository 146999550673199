import React from 'react';
import styled from 'styled-components';

import { withTheme } from '../theme';
import responsive from '../utilities/responsive';

const H1 = styled.h1`
  font-size: 2.488rem;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.25;

  ${responsive.sm.andSmaller`
    font-size: 2rem;
  `};
`;

const H2 = styled.h2`
  font-size: 2.074rem;
  margin-top: 1em;
  margin-bottom: 1em;

  ${responsive.sm.andSmaller`
    font-size: 1.75rem;
  `};
`;

const H3 = styled.h3`
  font-size: 1.728rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const H4 = styled.h4`
  font-size: 1.44erem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const H5 = styled.h5`
  font-size: 1.2rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const H6 = styled.h6`
  font-size: 1rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const Text = styled.span`
  font-size: ${withTheme(
    (theme, props) => theme.textSize[props.size || 'm'] || theme.textSize.m
  )};
  ${props => (props.wrap ? '' : 'white-space: nowrap;')}
`;

const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TruncatedText = ({ children }) => (
  <Truncate>
    <Text>{children}</Text>
  </Truncate>
);

const Paragraph = styled.p`
  font-size: ${withTheme(
    (theme, props) => theme.textSize[props.size || 'm'] || theme.textSize.m
  )};
  line-height: ${withTheme(
    (theme, props) => theme.lineHeight[props.size || 'm'] || theme.lineHeight.m
  )};
  margin: ${withTheme(
      (theme, props) =>
        theme.lineHeight[props.size || 'm'] || theme.lineHeight.m
    )}
    auto;
  opacity: ${props => (props.muted ? '.5' : 'initial')};
`;

const Code = styled.code`
  background-color: ${withTheme(theme =>
    theme.border.color.default.alpha(1).string()
  )};
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  color: ${withTheme(theme => theme.textColor.string())};
  padding: 2px 4px;
`;

export { H1, H2, H3, H4, H5, H6, Text, TruncatedText, Paragraph, Code };
