import styled from 'styled-components';
import theme, { withTheme } from '../lib/theme';
import { H1, H2 } from '../lib/components/typography';
import responsive from '../lib/utilities/responsive';

export const Section = styled.section`
  padding-top: 2em;
  padding-bottom: 2em;

  :last-child {
    margin-bottom: 2em;
  }
`;

export const PageTitle = styled(H1)``;

export const SectionTitle = styled(H2)`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
`;

export const Subtitle = styled.p`
  font-size: 1.5rem;
  line-height: 1.4;
`;

export const ExampleWrapper = styled.div`
  padding: 2em;
  border: 2px solid ${withTheme(theme => theme.border.color.default.string())};
`;

export const Wrapper = styled.div`
  background-color: ${props =>
    (props.theme.backgroundColor || theme.backgroundColor).string()};
  padding: 1em;
  color: ${props => (props.theme.textColor || theme.textColor).string()};
`;

export const Pre = styled.pre`
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-size: 0.75rem;
  line-height: 1.66;
  white-space: pre-wrap;
  color: ${withTheme(theme => theme.textColor.alpha(0.75).string())};
  background: ${withTheme(theme =>
    theme.border.color.default.alpha(0.5).string()
  )};
  border: 2px solid ${withTheme(theme => theme.border.color.default.string())};
  border-top: 0;
  padding: 0.75rem 1rem;
  -webkit-font-smoothing: auto;
`;

export const PreInline = styled(Pre)`
  display: inline-flex;
  border: none;
  color: ${withTheme(theme => theme.textColor.string())};

  ${responsive.sm.andSmaller`
    display: flex;
  `};
`;

export const Code = styled.code``;

export const Pre2 = styled.pre`
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-size: 0.75rem;
  line-height: 1.66;
  white-space: pre-wrap;
  color: ${withTheme(theme => theme.textColor.alpha(0.75).string())};
  background: ${withTheme(theme =>
    theme.border.color.default.alpha(0.5).string()
  )};
  border: 2px solid ${withTheme(theme => theme.border.color.default.string())};
  border-top: 0;
  -webkit-font-smoothing: auto;
`;

export const Code2 = styled.code`
  > pre {
    margin: 0 !important;
  }
`;
