import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';

import Input from '../html/Input';
import ControlError from '../errors/ControlError';
import TimeControlCore from './TimeControlCore';
import {
  hoursAndMinutesToMs,
  msToHoursAndMinutes,
  isInHoursAndMinutesFormat,
} from '../../utilities/data/time';
import { isFunction } from '../../utilities/check';

const TimeControl = props => {
  const {
    Control = Input,
    Error = ControlError,
    placeholder = '',
    autoFocus,
    value,
    onChange,
    isDisabled,
    name = 'time',
    error,
    hasError,
    internalValue,
    setInternalValue,
    isFocused,
    setIsFocused,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const handleOnFocus = event => {
    if (isFunction(onFocus)) {
      onFocus(event);
    }

    setIsFocused(true);
  };

  const handleOnBlur = event => {
    if (isFunction(onBlur)) {
      onBlur(event);
    }

    if (isInHoursAndMinutesFormat(internalValue)) {
      const newValue = hoursAndMinutesToMs(internalValue);
      onChange(newValue);
      setInternalValue(msToHoursAndMinutes(newValue));
    } else {
      onChange(undefined);
    }

    setIsFocused(false);
  };

  return (
    <Fragment>
      <TimeControlCore
        Control={Control}
        id={name}
        name={name}
        autoComplete="on"
        autoCapitalize="off"
        autoCorrect="off"
        placeholder={placeholder}
        autoFocus={autoFocus || false}
        value={
          value && !isFocused ? msToHoursAndMinutes(value) : internalValue || ''
        }
        onChange={value => setInternalValue(value)}
        disabled={isDisabled}
        hasError={hasError}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...rest}
      />
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </Fragment>
  );
};

TimeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default compose(
  withState('isFocused', 'setIsFocused', false),
  withState('internalValue', 'setInternalValue')
)(TimeControl);
